import { Holding } from "./household";
import { HoldingStructure } from "../enums/holding-structure";
import { HoldingType } from "../enums/holding-type";
import { OwnerType } from "./owner-type";
import { ensureHasValue } from "../shared/util";

export interface JourneyHolding extends Omit<Holding, "id"> {
    journeyID: string;
    journeyInstanceID: string;
    owner: OwnerType;
    created: Date;
    lastModified: Date;
    isDeleted: boolean;

    // Override the id to make it a string and required.
    holdingID: string;
}

export class JourneyHoldingContext {
    constructor(
        private readonly holdingType: HoldingType,
        public readonly owner: OwnerType,
    ) {
        let name: string;
        let holdingStructures: HoldingStructure[];
        switch (this.holdingType) {
            case HoldingType.Annuity:
                name = "Annuity";
                holdingStructures = [HoldingStructure.Annuity];
                break;
            case HoldingType.Pension:
                name = "Pension";
                holdingStructures = [HoldingStructure.Pension];
                break;
            case HoldingType.Superannuation:
                name = "Superannuation";
                holdingStructures = [HoldingStructure.Superannuation];
                break;
            case HoldingType.Investment:
                name = "Investment";
                holdingStructures = [HoldingStructure.Investment];
                break;
            case HoldingType.DefinedBenefit:
                name = "Defined Benefit";
                holdingStructures = [HoldingStructure.Superannuation, HoldingStructure.Pension];
                break;
            default:
                throw new Error(`holdingType is invalid: ${Number(this.holdingType)}`)
        }

        this.name = name;
        this.holdingStructures = holdingStructures;
    }

    private readonly name: string;
    private readonly holdingStructures: HoldingStructure[];

    readonly isSuper = () => this.holdingType === HoldingType.Superannuation;
    readonly isPension = () => this.holdingType === HoldingType.Pension;
    readonly isDefinedBenefit = () => this.holdingType === HoldingType.DefinedBenefit
    readonly isInvestment = () => this.holdingType === HoldingType.Investment;
    readonly isAnnuity = () => this.holdingType === HoldingType.Annuity;

    static createContext(holdingType: HoldingType, owner: OwnerType) {
        return new JourneyHoldingContext(holdingType, owner)
    }

    isItemForContext(item: JourneyHolding, includeJoint = true) {
        return (item.owner === this.owner || (includeJoint && item.owner === "joint")) &&
            this.getHoldingStructures().includes(item.structureId) && item.isDefinedBenefit === this.isDefinedBenefit();
    }

    isJointOwnerAllowed(): boolean {
        return [this.isAnnuity(), this.isInvestment()].some(x => x);
    }

    getHoldingStructures(): HoldingStructure[] {
        return ensureHasValue(this.holdingStructures);
    }

    getName(): string {
        return ensureHasValue(this.name);
    }
}