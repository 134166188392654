import { Injectable, signal } from '@angular/core';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { getCurrency, getNewItemGuid, mapJourneyIdToDtoId, one } from '../shared/util';
import { HouseholdPersonalAsset } from '../entities/household';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyClientService } from './journey-client.service';
import { JourneyPersonalAsset } from '../entities/journey-personal-asset';
import { JourneyService } from './journey.service';
import { MasterDataService } from './master-data.service';
import { NameValue } from '../entities/name-value';
import { OwnerType } from '../entities/owner-type';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';

@Injectable({
    providedIn: 'root'
})
export class JourneyPersonalAssetService {

    constructor(
        private http: HttpClient,
        private journeyService: JourneyService,
        private householdService: HouseholdService,
        private journeyClientService: JourneyClientService
    ) { }

    items = signal<JourneyPersonalAsset[]>([]);
    private _dataInitialised = false;
    private readonly apiPath = "/api/journeyPersonalAssets";

    get dataInitialised() {
        return this._dataInitialised;
    }

    async update(item: JourneyPersonalAsset) {
        await this.saveToApi([item]);
        this.items
            .update(x => [...x.filter(y => y.personalAssetID !== item.personalAssetID), item]);
    }

    async delete(item: JourneyPersonalAsset) {
        item.isDeleted = true;
        await this.saveToApi([item]);
        this.items.update(x => x.filter(y => y.personalAssetID !== item.personalAssetID));
    }

    async add(item: JourneyPersonalAsset) {
        item.personalAssetID = getNewItemGuid();
        await this.saveToApi([item]);
        this.items.update(x => [...x, item]);
    }

    mapJourneyToDtoForOwner(owner: OwnerType): HouseholdPersonalAsset[] {
        this.ensureDataInitialised();
        return this.items()
            .filter(x => x.owner === owner)
            .map(x => ({
                id: mapJourneyIdToDtoId(x.personalAssetID),
                name: x.name,
                value: x.value,
                typeId: x.typeId,
            }));
    }

    async refresh() {
        const household = this.householdService.household;
        if (!household) {
            throw new Error("The household object has not been initialised.");
        }

        const now = new Date();

        const items: JourneyPersonalAsset[] = [];
        items.push(...household.personalAssets.map(x => this.mapDtoToJourney(x, "joint", now)));
        items.push(...household.client.personalAssets.map(x => this.mapDtoToJourney(x, "primary", now)));
        items.push(...household.spouse?.personalAssets.map(x => this.mapDtoToJourney(x, "spouse", now)) ?? []);

        await this.saveToApi(items);
        this.items.set(items);
        this._dataInitialised = true;
    }

    refreshFromApi() {
        this.ensureDataInitialised();
        return this.getFromApi()
            .pipe(
                tap((items) => { this.items.set(items) })
            );
    }

    ensureDataInitialised() {
        if (!this.dataInitialised) {
            throw new Error("The data has not been initialised.");
        }
    }

    async loadJourneyPersonalAssets(previousJourneyInstanceID: string): Promise<void> {
        const items = await firstValueFrom(this.getFromApi(previousJourneyInstanceID));

        const now = new Date();
        for (const item of items) {
            item.journeyInstanceID = this.journeyService.getNonNullableJourney().journeyInstanceID;
            item.created = now;
            item.lastModified = now;
        }

        await this.saveToApi(items);
        this.items.set(items);
        this._dataInitialised = true;
    }

    private mapDtoToJourney(dto: HouseholdPersonalAsset, owner: OwnerType, now: Date): JourneyPersonalAsset {
        if (!dto.id) {
            throw new Error("A personal asset object cannot have null value for its id column.");
        }

        return {
            value: dto.value,
            name: dto.name,
            typeId: dto.typeId,
            journeyID: this.journeyService.getNonNullableJourney().journeyID,
            journeyInstanceID: this.journeyService.getNonNullableJourney().journeyInstanceID,
            owner,
            lastModified: now,
            created: now,
            isDeleted: false,
            personalAssetID: dto.id.toString()
        };
    }

    private getFromApi(previousJourneyInstanceID?: string): Observable<JourneyPersonalAsset[]> {
        const journeyId = this.journeyService.getNonNullableJourney().journeyID;
        const journeyInstanceId = previousJourneyInstanceID ?? this.journeyService.getNonNullableJourney().journeyInstanceID;
        return this.http.get<JourneyPersonalAsset[]>(`${this.apiPath}/${journeyId}/${journeyInstanceId}/latest`);
    }

    private async saveToApi(items: JourneyPersonalAsset[]): Promise<void> {
        await firstValueFrom(this.http.post(`${this.apiPath}`, items));
    }

    getPdfSections(): PdfSection[] {
        const pdfSections: PdfSection[] = [];

        for (const [index, item] of this.items().entries()) {
            const pdfSection: PdfSection = {
                breakLine: false,
                pdfSectionType: PdfSectionTypes.Table,
                title: `Personal asset ${index + one}`,
                content: {
                    tableHeaders: [
                        {
                            name: "Field",
                            width: "30%"
                        },
                        {
                            name: this.journeyClientService.getOwnerDropdownName(item.owner),
                            width: "60%"
                        }
                    ],
                    tableRows: []
                }
            };

            const nameValues = this.getJourneyPersonalAssetFields(item);

            (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x)));

            pdfSections.push(pdfSection);
        }

        return pdfSections;
    }

    private getJourneyPersonalAssetFields(journeyPersonalAsset: JourneyPersonalAsset): NameValue[] {
        return [
            {
                name: "Name",
                value: journeyPersonalAsset.name,
            },
            {
                name: "Value",
                value: getCurrency(journeyPersonalAsset.value)
            },
            {
                name: "Type",
                value: MasterDataService.getNameForId(journeyPersonalAsset.typeId, MasterDataService.getPersonalAssetTypes())
            },
            {
                name: "Owner",
                value: this.journeyClientService.getOwnerDropdownName(journeyPersonalAsset.owner)
            }
        ];
    }
}