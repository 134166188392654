import { Injectable, signal } from '@angular/core';
import { JourneyHolding, JourneyHoldingContext, } from '../entities/journey-holding';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { getBooleanString, getCurrency, getNewItemGuid, mapJourneyIdToDtoId, one } from '../shared/util';
import { AssetType } from '../enums/asset-type';
import { Holding } from '../entities/household';
import { HoldingType } from '../enums/holding-type';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyClientService } from './journey-client.service';
import { JourneyService } from './journey.service';
import { MasterDataService } from './master-data.service';
import { NameValue } from '../entities/name-value';
import { OwnerType } from '../entities/owner-type';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';

@Injectable({
    providedIn: 'root'
})
export class JourneyHoldingService {
    constructor(
        private http: HttpClient,
        private journeyService: JourneyService,
        private householdService: HouseholdService,
        private journeyClientService: JourneyClientService
    ) { }

    items = signal<JourneyHolding[]>([]);
    private _dataInitialised = false;
    private readonly apiPath = "/api/journeyHoldings";

    get dataInitialised() {
        return this._dataInitialised;
    }

    async update(item: JourneyHolding) {
        await this.saveToApi([item]);
        this.items.update(x => [...x.filter(y => y.holdingID !== item.holdingID), item]);
    }

    async delete(item: JourneyHolding) {
        item.isDeleted = true;
        await this.saveToApi([item]);
        this.items.update(x => x.filter(y => y.holdingID !== item.holdingID));
    }

    async add(item: JourneyHolding) {
        item.holdingID = getNewItemGuid();
        await this.saveToApi([item]);
        this.items.update(x => [...x, item]);
    }

    mapJourneyToDtoForOwner(owner: OwnerType): Holding[] {
        this.ensureDataInitialised();
        return this.items()
            .filter(x => x.owner === owner)
            .map(x => ({
                id: mapJourneyIdToDtoId(x.holdingID),
                incomeAmount: x.incomeAmount,
                balance: x.balance,
                structureId: x.structureId,
                incomeFrequency: x.incomeFrequency,
                name: x.name,
                assetTypeId: x.assetTypeId,
                accountNumber: x.accountNumber,
                isDefinedBenefit: x.isDefinedBenefit,
                isPurchasedFromSuper: x.isPurchasedFromSuper
            }));
    }

    async refresh() {
        const household = this.householdService.household;
        if (!household) {
            throw new Error("The household object has not been initialised.");
        }

        const items: JourneyHolding[] = [];
        const now = new Date();

        items.push(...household.holdings.map(x => this.mapDtoToJourney(x, "joint", now)));
        items.push(...household.client.holdings.map(x => this.mapDtoToJourney(x, "primary", now)));
        items.push(...household.spouse?.holdings.map(x => this.mapDtoToJourney(x, "spouse", now)) ?? []);

        await this.saveToApi(items);
        this.items.set(items);
        this._dataInitialised = true;
    }

    refreshFromApi(): Observable<JourneyHolding[]> {
        this.ensureDataInitialised();
        return this.getFromApi()
            .pipe(
                tap((items) => { this.items.set(items) }));
    }

    ensureDataInitialised() {
        if (!this.dataInitialised) {
            throw new Error("The data has not been initialised.");
        }
    }

    async loadJourneyHoldings(previousJourneyInstanceID: string): Promise<void> {
        const items = await firstValueFrom(this.getFromApi(previousJourneyInstanceID));

        const now = new Date();

        for (const item of items) {
            item.journeyInstanceID = this.journeyService.getNonNullableJourney().journeyInstanceID;
            item.created = now;
            item.lastModified = now;
        }

        await this.saveToApi(items);
        this.items.set(items);
        this._dataInitialised = true;
    }

    private mapDtoToJourney(dto: Holding, owner: OwnerType, now: Date): JourneyHolding {
        if (!dto.id) {
            throw new Error("A holding object cannot have null value for its id column.");
        }

        return {
            holdingID: dto.id.toString(),
            accountNumber: dto.accountNumber,
            assetTypeId: dto.assetTypeId,
            incomeAmount: dto.incomeAmount,
            balance: dto.balance,
            structureId: dto.structureId,
            name: dto.name,
            incomeFrequency: dto.incomeFrequency,
            isDefinedBenefit: dto.isDefinedBenefit,
            isPurchasedFromSuper: dto.isPurchasedFromSuper,

            journeyID: this.journeyService.getNonNullableJourney().journeyID,
            journeyInstanceID: this.journeyService.getNonNullableJourney().journeyInstanceID,
            owner,
            created: now,
            lastModified: now,
            isDeleted: false
        };
    }

    private getFromApi(previousJourneyInstanceID?: string): Observable<JourneyHolding[]> {
        const journeyId = this.journeyService.getNonNullableJourney().journeyID;
        const journeyInstanceId = previousJourneyInstanceID ?? this.journeyService.getNonNullableJourney().journeyInstanceID;
        return this.http.get<JourneyHolding[]>(`${this.apiPath}/${journeyId}/${journeyInstanceId}/latest`);
    }

    private async saveToApi(items: JourneyHolding[]): Promise<void> {
        await firstValueFrom(this.http.post(this.apiPath, items));
    }

    // This method can't be static because it needs to be callable through DI.
    // eslint-disable-next-line class-methods-use-this
    public mapAssetTypeToDropdownOption(assetTypeId: number | null | undefined): number | null {
        return assetTypeId ?? AssetType.Holding;
    }

    // This method can't be static because it needs to be callable through DI.
    // eslint-disable-next-line class-methods-use-this
    public mapDropdownOptionToAssetType(assetTypeId: number): number | null {
        return assetTypeId === AssetType.Holding.valueOf()
            ? null
            : assetTypeId;
    }

    getPdfSections(): PdfSection[] {
        const pdfSections: PdfSection[] = [];

        const superannuations = this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.Superannuation, "primary")).isItemForContext(x));
        const pension = this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.Pension, "primary")).isItemForContext(x));
        const investment = this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.Investment, "primary")).isItemForContext(x));
        const annuity = this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.Annuity, "primary")).isItemForContext(x));
        const definedBenefit = this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.DefinedBenefit, "primary")).isItemForContext(x));

        if (this.journeyClientService.spouseJourneyClient()) {
            // Pass false to isItemForContext to exclude the joint holdings as we already obtained them above.
            superannuations.push(...this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.Superannuation, "spouse")).isItemForContext(x, false)));
            pension.push(...this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.Pension, "spouse")).isItemForContext(x, false)));
            investment.push(...this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.Investment, "spouse")).isItemForContext(x, false)));
            annuity.push(...this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.Annuity, "spouse")).isItemForContext(x, false)));
            definedBenefit.push(...this.items().filter(x => (JourneyHoldingContext.createContext(HoldingType.DefinedBenefit, "spouse")).isItemForContext(x, false)));
        }

        pdfSections.push(...this.getHoldingPDFSections(superannuations, HoldingType.Superannuation, "Superannuation"));
        pdfSections.push(...this.getHoldingPDFSections(pension, HoldingType.Pension, "Pension"));
        pdfSections.push(...this.getHoldingPDFSections(investment, HoldingType.Investment, "Investment"));
        pdfSections.push(...this.getHoldingPDFSections(annuity, HoldingType.Annuity, "Annuity"));
        pdfSections.push(...this.getHoldingPDFSections(definedBenefit, HoldingType.DefinedBenefit, "Defined Benefits"));

        return pdfSections;
    }

    private getHoldingPDFSections(items: JourneyHolding[], holdingType: HoldingType, name: string): PdfSection[] {
        const pdfSections: PdfSection[] = [];

        for (const [index, item] of items.entries()) {
            const pdfSection: PdfSection = {
                breakLine: false,
                pdfSectionType: PdfSectionTypes.Table,
                title: `${name} ${index + one}`,
                content: {
                    tableHeaders: [
                        {
                            name: "Field",
                            width: "30%"
                        },
                        {
                            name: this.journeyClientService.getOwnerDropdownName(item.owner),
                            width: "60%"
                        }
                    ],
                    tableRows: []
                }
            };

            const nameValues = this.getHoldingFields(item, holdingType);

            (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x)));

            pdfSections.push(pdfSection);
        }

        return pdfSections;
    }

    // eslint-disable-next-line complexity
    private getHoldingFields(journeyHolding: JourneyHolding, holdingType: HoldingType): NameValue[] {
        const nameValues = [
            {
                name: "Asset Name",
                value: journeyHolding.name,
            },
            {
                name: "Value",
                value: getCurrency(journeyHolding.balance)
            },
            {
                name: "Account No",
                value: journeyHolding.accountNumber
            }
        ];

        if (holdingType === HoldingType.Investment) {
            nameValues.push({
                name: "Type",
                value: MasterDataService.getNameForIdWithDefault(this.mapAssetTypeToDropdownOption(journeyHolding.assetTypeId), MasterDataService.getAssetTypes())
            });
        }

        if (holdingType === HoldingType.Pension || holdingType === HoldingType.DefinedBenefit || holdingType === HoldingType.Annuity) {
            nameValues.push({
                name: "Income ($p.a.)",
                value: getCurrency(journeyHolding.incomeAmount)
            });
        }

        if (holdingType === HoldingType.Annuity) {
            nameValues.push({
                name: "Purchased with super funds?",
                value: getBooleanString(journeyHolding.isPurchasedFromSuper)
            });
        }

        if (holdingType === HoldingType.Pension) {
            nameValues.push({
                name: "Frequency",
                value: journeyHolding.incomeFrequency
            });
        }

        if (holdingType === HoldingType.DefinedBenefit) {
            nameValues.push({
                name: "Defined Benefit Type",
                value: MasterDataService.getNameForIdWithDefault(journeyHolding.structureId, MasterDataService.getHoldingStructures(), "-")
            });
        }

        nameValues.push({
            name: "Owner",
            value: this.journeyClientService.getOwnerDropdownName(journeyHolding.owner)
        });

        return nameValues;
    }
}