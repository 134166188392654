import { Injectable, signal } from '@angular/core';
import { Observable, firstValueFrom, tap } from 'rxjs';
import { PdfSection, TableContent } from '../entities/pdf-content';
import { getCurrency, getNewItemGuid, mapJourneyIdToDtoId, one, percentConverter } from '../shared/util';
import { liabilityInterestRateDecimalPlaces, liabilityTaxDeductibilityDecimalPlaces } from '../shared/constants';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { JourneyClientService } from './journey-client.service';
import { JourneyLiability } from '../entities/journey-liability';
import { JourneyService } from './journey.service';
import { Liability } from '../entities/household';
import { MasterDataService } from './master-data.service';
import { NameValue } from '../entities/name-value';
import { OwnerType } from '../entities/owner-type';
import { PdfSectionTypes } from '../enums/pdf-section-type';
import { PdfService } from './pdf.service';

@Injectable({
    providedIn: 'root'
})
export class JourneyLiabilityService {

    constructor(
        private http: HttpClient,
        private journeyService: JourneyService,
        private householdService: HouseholdService,
        private journeyClientService: JourneyClientService
    ) { }

    items = signal<JourneyLiability[]>([]);
    private _dataInitialised = false;
    private readonly apiPath = "/api/journeyLiabilities";

    get dataInitialised() {
        return this._dataInitialised;
    }

    async update(item: JourneyLiability) {
        await this.saveToApi([item]);
        this.items
            .update(x => [...x.filter(y => y.liabilityID !== item.liabilityID), item]);
    }

    async delete(item: JourneyLiability) {
        item.isDeleted = true;
        await this.saveToApi([item]);
        this.items.update(x => x.filter(y => y.liabilityID !== item.liabilityID));
    }


    async add(item: JourneyLiability) {
        item.liabilityID = getNewItemGuid()
        await this.saveToApi([item]);
        this.items.update(x => [...x, item]);
    }

    mapJourneyToDtoForOwner(owner: OwnerType): Liability[] {
        this.ensureDataInitialised();
        return this.items()
            .filter(x => x.owner === owner)
            .map(x => ({
                id: mapJourneyIdToDtoId(x.liabilityID),
                name: x.name,
                value: x.value,
                typeId: x.typeId,
                repaymentTypeId: x.repaymentTypeId,
                monthlyRepayment: x.monthlyRepayment,
                interestRate: x.interestRate,
                taxDeductiblePercentage: x.taxDeductiblePercentage,
                yearsRemaining: x.yearsRemaining,
                repaymentFrequencyId: x.repaymentFrequencyId,
            }));
    }

    async refresh() {
        const household = this.householdService.household;
        if (!household) {
            throw new Error("The household object has not been initialised.");
        }

        const now = new Date();
        const items: JourneyLiability[] = [];
        items.push(...household.liabilities.map(x => this.mapDtoToJourney(x, "joint", now)));
        items.push(...household.client.liabilities.map(x => this.mapDtoToJourney(x, "primary", now)));
        items.push(...household.spouse?.liabilities.map(x => this.mapDtoToJourney(x, "spouse", now)) ?? []);

        await this.saveToApi(items);
        this.items.set(items);
        this._dataInitialised = true;
    }

    refreshFromApi(): Observable<JourneyLiability[]> {
        this.ensureDataInitialised();
        return this.getFromApi()
            .pipe(
                tap(items => { this.items.set(items) })
            )
    }

    ensureDataInitialised() {
        if (!this.dataInitialised) {
            throw new Error("The data has not been initialised.");
        }
    }

    getPdfSections(): PdfSection[] {
        const pdfSections: PdfSection[] = [];

        for (const [index, item] of this.items().entries()) {
            const pdfSection: PdfSection = {
                breakLine: false,
                pdfSectionType: PdfSectionTypes.Table,
                title: `Liability ${index + one}`,
                content: {
                    tableHeaders: [
                        {
                            name: "Field",
                            width: "30%"
                        },
                        {
                            name: this.journeyClientService.getOwnerDropdownName(item.owner),
                            width: "60%"
                        }
                    ],
                    tableRows: []
                }
            };

            const nameValues = this.getJourneyLiabilityFields(item);

            (pdfSection.content as TableContent).tableRows.push(...nameValues.map(x => PdfService.getTableRow(x)));

            pdfSections.push(pdfSection);
        }

        return pdfSections;
    }

    async loadJourneyLiabilities(previousJourneyInstanceID: string): Promise<void> {
        const items = await firstValueFrom(this.getFromApi(previousJourneyInstanceID));

        const now = new Date();
        for (const item of items) {
            item.journeyInstanceID = this.journeyService.getNonNullableJourney().journeyInstanceID;
            item.created = now;
            item.lastModified = now;
        }

        await this.saveToApi(items);
        this.items.set(items);
        this._dataInitialised = true;
    }

    private getJourneyLiabilityFields(journeyLiability: JourneyLiability): NameValue[] {
        return [
            {
                name: "Liability Name",
                value: journeyLiability.name,
            },
            {
                name: "Loan Type",
                value: MasterDataService.getNameForId(journeyLiability.typeId, MasterDataService.getLoanTypes())
            },
            {
                name: "Value",
                value: getCurrency(journeyLiability.value)
            },
            {
                name: "Repayment Type",
                value: MasterDataService.getNameForIdWithDefault(journeyLiability.repaymentTypeId, MasterDataService.getLoanRepaymentTypes())
            },
            {
                name: "Monthly Repayment",
                value: getCurrency(journeyLiability.monthlyRepayment)
            },
            {
                name: "Repayment Frequency",
                value: MasterDataService.getNameForIdWithDefault(journeyLiability.repaymentFrequencyId, MasterDataService.getPaymentFrequencyTypes())
            },
            {
                name: "Interest Rate",
                value: journeyLiability.interestRate ? `${percentConverter(journeyLiability.interestRate, "decimalToPercent", liabilityInterestRateDecimalPlaces)}%` : "-"
            },
            {
                name: "Tax Deductible Percentage",
                value: journeyLiability.taxDeductiblePercentage ? `${percentConverter(journeyLiability.taxDeductiblePercentage, "decimalToPercent", liabilityTaxDeductibilityDecimalPlaces)}%` : "-"
            },
            {
                name: "Years Remaining",
                value: journeyLiability.yearsRemaining
            },
            {
                name: "Owner",
                value: this.journeyClientService.getOwnerDropdownName(journeyLiability.owner)
            }
        ];
    }

    private mapDtoToJourney(dto: Liability, owner: OwnerType, now: Date): JourneyLiability {
        if (!dto.id) {
            throw new Error("A liability object cannot have null value for its id column.");
        }

        return {
            value: dto.value,
            typeId: dto.typeId,
            repaymentTypeId: dto.repaymentTypeId,
            repaymentFrequencyId: dto.repaymentFrequencyId,
            monthlyRepayment: dto.monthlyRepayment,
            name: dto.name,
            interestRate: dto.interestRate,
            taxDeductiblePercentage: dto.taxDeductiblePercentage,
            yearsRemaining: dto.yearsRemaining,
            journeyID: this.journeyService.getNonNullableJourney().journeyID,
            journeyInstanceID: this.journeyService.getNonNullableJourney().journeyInstanceID,
            owner,
            created: now,
            lastModified: now,
            isDeleted: false,
            liabilityID: dto.id.toString()
        };
    }

    private getFromApi(previousJourneyInstanceID?: string): Observable<JourneyLiability[]> {
        const journeyId = this.journeyService.getNonNullableJourney().journeyID;
        const journeyInstanceId = previousJourneyInstanceID ?? this.journeyService.getNonNullableJourney().journeyInstanceID;
        return this.http.get<JourneyLiability[]>(`${this.apiPath}/${journeyId}/${journeyInstanceId}/latest`);
    }

    private async saveToApi(items: JourneyLiability[]): Promise<void> {
        await firstValueFrom(this.http.post(`${this.apiPath}`, items));
    }
}